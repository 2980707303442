<template>
    <div>
        <div class="total-title__line"></div>
        <div class="total-title">企业销售占比图
            <div class="total-title__right">筛选：
                <ts-year-select v-model="year" @change="showDoseRate()"></ts-year-select>
            </div>
        </div>
        <ts-error v-if="noAuth"></ts-error>
        <div  v-else :id="nameKey" class="canvas small" v-loading="loading"></div>
    </div>
</template>

<script>
    export default {
        props: {
            comCode: {},
        },
        created() {
            this.showDoseRate()
        },
        data() {
            return {
                year: this.$variable.lastYear.key,
                noAuth: false,
                loading: false,
                nameKey: new Date().getTime() + 'rate',
                data: [],
            }
        },
        methods: {
            showDoseRate() {
                this.loading = true
                this.$api.post('drug/saleYear/drugCompRatioAndAmount', {
                    model: this.$variable.yearMap[this.year],
                    params:{
                        comCode: this.comCode,
                    }
                })
                    .then(res => {
                        if (res.code !== '00000') {
                            this.noAuth = true
                        } else {
                            let total = 0
                            res.data
                                .sort((x, y)  => {
                                    return parseFloat(y.amount) - parseFloat(x.amount)
                                })
                                .splice(10)
                            this.data = res.data
                                .map(item => {
                                    const value = this.$help.parseFloat100(item.ratio)
                                    item.name = item.compName
                                    item.value = value
                                    total += value
                                    return item
                                })
                            if (total < 100 && this.data.length === 10) {
                                this.data.push({
                                    name: '其他',
                                    value: 100 - total
                                })
                            }
                            this.$help.renderPie(this.nameKey, this.data)
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
        }
    }
</script>